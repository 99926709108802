import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import {getAnalytics} from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyC7J-HDjNr8hOehvCTEl8lyVxJI0ftg95w",
    authDomain: "hospilocal-bdbf6.firebaseapp.com",
    projectId: "hospilocal-bdbf6",
    storageBucket: "hospilocal-bdbf6.appspot.com",
    messagingSenderId: "981909789682",
    appId: "1:981909789682:web:ecbcade40a3abcbcf5e96b",
    measurementId: "G-BVX13XMX50"
};

const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)
export const db = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
