import { Navigate } from "react-router-dom";
import Spinner from "./Spinner";
import { auth } from "../firebaseSetup";
import { useState, useEffect } from "react";

const ProtectedRoute = ({ Component}) => {
    // assume user to be logged out
    const [loggedIn, setLoggedIn] = useState(false);
  
    // keep track to display a spinner while auth status is being checked
    const [checkingStatus, setCheckingStatus] = useState(true);
  
    useEffect(() => {
      // auth listener to keep track of user signing in and out
      auth.onAuthStateChanged((user) => {
        if (user) {
          setLoggedIn(true);
        }
  
        setCheckingStatus(false);
      });
    }, []);

    return(
        checkingStatus? <Spinner/> :
        loggedIn? 
        <Component/> 
        :
        <Navigate to={"/"} replace/>
    )
  };
  
  export default ProtectedRoute;