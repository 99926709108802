import {useState} from 'react'
import {Input, Grid, Alert} from 'theme-ui'
import { Form, Formik} from 'formik'
import * as Yup from 'yup'
import styled from "styled-components";
import {Button, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {auth} from "../firebaseSetup";
import {signInWithEmailAndPassword} from "firebase/auth";
import Img from "../assets/logo-03.png";


const PageWrapper= styled.div`
    background-color: #white;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FormWrapper= styled.div`
    width: 80%;
    height: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(1, 1, 1, 1);
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    margin-top: 10%;
    padding: 5%
`



const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required').min(8).max(200),
})

export const SignIn = () => {
    const [formError, setFormError] = useState('')


    return (
        <PageWrapper>
            <style type="text/css">
                {`
    .btn-primary {
      background-color: rgb(62, 180, 137);
      color: white;
      border-color: rgb(62, 180, 137);
    }
    .btn-secondary {
      background-color: white;
      color: black;
    }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    `}
            </style>
        <FormWrapper>
            <Image src={Img} rounded width={"30%"}/>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={async (values) => {

                    try {
                        await signInWithEmailAndPassword(auth, values.email, values.password)
                        console.log(auth.currentUser)
                    } catch (error) {
                        console.log(error)
                        let errorMessage = 'error.unknown'
                        if (typeof error === 'string') {
                            errorMessage = error.toUpperCase()
                        } else if (error instanceof Error) {
                            errorMessage = error.message
                        }
                        setFormError(errorMessage)

                    }
                }}
                validationSchema={SignInSchema}
            >
                {({ getFieldProps }) => (
                    <Form>
                            <Input
                                sx={{ borderColor: 'rgb(209, 218, 230)' }}
                                {...getFieldProps('email')}
                                id="email"
                                placeholder={"Enter Email"}
                            />
                            <Input
                                sx={{ width: '19rem', borderColor: 'rgb(209, 218, 230)' }}
                                {...getFieldProps('password')}
                                type="password"
                                id="password"
                                placeholder={"Enter Password"}
                            />
                        <Grid>
                            <Button  type="submit" variant="primary" >
                                Log in
                            </Button>
                        </Grid>
                        <br />
                        {formError && <Alert variant="error">{formError}</Alert>}
                    </Form>
                )}
            </Formik>
        </FormWrapper>
        </PageWrapper>
    )
}
