 import { Link} from 'react-router-dom'
import {signOut} from "firebase/auth";
import {auth} from "../firebaseSetup";
import {Sidebar, Menu, MenuItem} from 'react-pro-sidebar';
 import HomeIcon from '@mui/icons-material/Home';
 import SearchIcon from '@mui/icons-material/Search';
 import PaymentIcon from '@mui/icons-material/Payment';
 import LogoutIcon from '@mui/icons-material/Logout';
 import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
 import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
 import {useState} from "react";
 import { Navigate } from "react-router-dom";


const SideBar = ()=>{
    const [collapsed, setCollapsed] = useState(true);
    return(
            <Sidebar  backgroundColor={"rgb(20,36,72)"} collapsed={collapsed}>
                <Menu
                    menuItemStyles={{
                        button: {
                                    color: 'white',

                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                            },
                                }

                    }}
                >
                    <MenuItem
                        icon={collapsed?<ArrowCircleRightOutlinedIcon/>:<ArrowCircleLeftOutlinedIcon/>}
                        className={'menu1'}
                        component={<div onClick={()=>setCollapsed(!collapsed)}/>}
                    >
                        <h3>Hospilocal</h3>
                    </MenuItem>
                    <MenuItem component={<Link to="/home" />} icon={<HomeIcon/>}> Dashboard</MenuItem>
                    <MenuItem component={<Link to="/verify" />} icon={<SearchIcon/>}> Verify</MenuItem>
                    <MenuItem component={<Link to="/payment" />} icon={<PaymentIcon/>}> Payments</MenuItem>

                </Menu>

                <Menu
                    menuItemStyles={{
                        button: {
                            color: 'white',

                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                            },
                        }

                    }}
                >

                    <MenuItem
                        component={<div onClick={()=>{
                            signOut(auth);
                            <Navigate to={"/"} replace/>
                        }}/>}
                        icon={<LogoutIcon/>}
                        // rootStyles={{backgroundColor: 'rgb(62, 180, 137)'}}
                        className={'menuBottom'}
                    >
                        Log Out
                    </MenuItem>
                </Menu>
            </Sidebar>

    )
}

export default SideBar
