import axios from "axios";




export const callExternalApi = async(config) => {
    try {
        const response = await axios(config);
        const {data} = response;
        return {
            data,
            error: null,
        }
    } catch (error){
        return {
            data: null,
            error: {
                message: (error).message,
            }
        }
    }
}