import { callExternalApi } from "./externalApi"


export const getPaystackBalance = async()=>{
    const config = {
        url: `https://api.paystack.co/balance`,
        method: 'GET',
        headers: {
            'Authorization': 'Bearer sk_live_6b42fc122edf633f86e5336e8b0ec5c8c32c1c3d',
        }
    }
    const {data, error} = await callExternalApi(config)
    return {
        data,
        error,
    };
};