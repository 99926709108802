
import {collection, query, where, getDocs} from "firebase/firestore";
import {auth, db} from "../firebaseSetup";

export const getPaymentDue= async() => {
    const paymentDueDocs = query(collection(db, "payments"), where("status", "==", "due"));
    try{
        const querySnapshot = await getDocs(paymentDueDocs);
        let total = 0
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            total = total + doc.data().amount
        });
        return {
            data: total,
            error: null
        }
    }catch(error){
        return {
            data: null,
            error: error
        }
    }
    
}

export const getLoggedInUserData= async() => {
    const userDocs = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
    try{
        const querySnapshot = await getDocs(userDocs);
        let userData = null
        querySnapshot.forEach((doc) => {
            // doc.data() should always return one item in list or empty list
            userData = doc.data();
        });
        return {
            data: userData,
            error: null
        }
    }catch(error){
        return {
            data: null,
            error: error
        }
    }
    
}

export const getAllUsers= async() => {
    const userDocs = collection(db, "users");
    try{
        const querySnapshot = await getDocs(userDocs);
        let usersData = []
        querySnapshot.forEach((doc) => {
            usersData.push(doc.data());
        });
        return {
            data: usersData,
            error: null
        }
    }catch(error){
        return {
            data: null,
            error: error
        }
    }
    
}