import {useEffect} from 'react'
import  {useNavigate}  from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import {auth, db} from "../firebaseSetup";
import {collection, getDocs, query, where} from "firebase/firestore";

export const Public = () => {
    const history = useNavigate()


    useEffect(() => {

        auth.onAuthStateChanged(async user => {
            if (user) {
                const loggedUser = query(collection(db, "users"), where("uid", "==", user.uid));
                const querySnapshot = await getDocs(loggedUser)
                let userType = '';
                querySnapshot.forEach((doc) => {
                    userType = doc.data().userType;
                });
                if (userType === "admin") {
                    history("/home");
                }
            } else {
                history("/signin");
            }
        })
// eslint-disable-next-line
    }, [])




}
