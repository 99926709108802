import {Main} from "./Home"
import {ApprovalTable} from "../components/ApprovalTable";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {ImageList, ImageListItem, ImageListItemBar, Button, Container} from "@mui/material";
import {DatePicker} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {collection, query, where, doc, updateDoc, onSnapshot, getDoc, addDoc} from "firebase/firestore";
import {db} from "../firebaseSetup";
import SideBar from "../components/Sidebar";
import {Dropdown, DropdownButton} from "react-bootstrap";



export const Overlay = styled.div `
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4);
    overflow-x: hidden;
    transition: 0.5s;
`

export const OverlayContent =styled.div`
    position: relative;
    width: 80%;
    height: 100%;
    text-align: center;
    margin: 0 auto;
    color: black;
    background-color: #F6F6F8;
`

export const OverlayLink = styled.a `
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.
    3s;
    &:hover, &:focus {
    color: #f1f1f1;
    }
`


 export const OverlayClosebtn  = styled.button`
    position: absolute;
    top: 1rem;
    right: 3rem;
    font-size: 4rem;
`


export const PictureCard =styled.div`
    position: relative;
    max-width: 345;
    max-height: 345;
    text-align: center;
    margin: 0 auto;
`

const DrowpDownWrapper = styled.div`
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    `

const ButtonsWrapper = styled.div`
    flex-direction: row;
    align-items: space-evenly;
    flex: 1;
`


export const Approvals = () => {
    const [userArray, setUserArray]=useState([])
    const [rejectionReason, setRejectionReason] = useState()
    const [licenseExpiryDate, setLicenseExpiryDate] = useState()

    const handleApproval = async (certState, userId) => {
        if (userId){
            const docRef = doc(db, 'users', userId);
            const docSnap = await getDoc(docRef);

            if (certState==='rejected'){
                if (!rejectionReason){
                    alert("Choose a reason for rejection")
                    return
                }
                await updateDoc(docRef, {
                    certified: 'pending',
                    license: null,
                    selfPhoto: null,
                    governmentId: null,
                });

                await addDoc(collection(db, "messages"), {
                    to: docSnap.data().phoneNumber,
                    body:
                        'Hi ' +
                        docSnap.data().firstname +
                        ', Your Hospilocal account review has been unsuccessful for the reason: '+rejectionReason+'. Please resubmit your details for another review',
                });

                setState({show_overlay:false})

            } else{
                if (!licenseExpiryDate){
                    alert('Enter the license expiry date')
                    return
                }
                await updateDoc(docRef, {
                    certified: 'approved',
                    licenseExpiryDate: licenseExpiryDate
                });
                await addDoc(collection(db, "messages"), {
                    to: docSnap.data().phoneNumber,
                    body:
                        'Hi ' +
                        docSnap.data().firstname +
                        ', Your Hospilocal account review has been successful. Login and choose your availability to start getting booked. Share your profile with your network to supercharge and increase your bookings!',
                });
                setState({show_overlay:false})
            }


        }


    }

    useEffect(()=>{
        const fetchData = async () => {
            const approvals = query(collection(db, "users"),where("certified","==","pending"));
            let profArray=[];

            onSnapshot(approvals, snapshot => {
                snapshot.docs.map(doc => (
                    doc.data().selfPhoto && doc.data().governmentId && doc.data().license? profArray.push(doc.data()):console.log('incomplete submission')
                ))

                setUserArray(profArray)
            })

        }

        fetchData().catch(error=>console.log(error))

    },[])


    const [state , setState]  = useState({show_overlay: false})
    const [activeUser, setActiveUser] = useState({})

    const handleApprove = (rowIndex) => {
        setState({show_overlay: true})
        if (userArray){
            setActiveUser(userArray[rowIndex])
        }


    };


    return (
        <div style={{ display: "flex", height:'100%', minHeight:'850px' }}>
            <SideBar />
            <Main>
                <h4 style={{marginTop:"10px" , marginBottom:"10px"}}>Verification of Health Professionals</h4>
                <h6 style={{marginTop:"10px" , marginBottom:"10px"}}>Pending: {userArray.length}</h6>
                <ApprovalTable userData={userArray} handleApprove={handleApprove}/>
                <div
                    style={{display: state.show_overlay === true ? 'block' : 'none'}}
                >
                    <Overlay>
                        <OverlayContent>
                            <Container>
                            <Container>
                            <ImageList sx={{ width: "100%", height: "60%" }} cols={3} >

                                    <ImageListItem key={activeUser.license}>
                                        <img
                                            src={activeUser.license}
                                            alt={activeUser.uid}
                                            loading="lazy"
                                            style={{maxHeight:'450px', marginTop:'40px'}}
                                        />
                                        <ImageListItemBar position="below" title={"License"} />
                                    </ImageListItem>
                                <ImageListItem key={activeUser.selfPhoto}>
                                    <img
                                        src={activeUser.selfPhoto}
                                        alt={activeUser.uid}
                                        loading="lazy"
                                        style={{maxHeight:'450px', marginTop:'40px'}}
                                    />
                                    <ImageListItemBar position="below" title={"Selfie"} />
                                </ImageListItem>
                                <ImageListItem key={activeUser.governmentId}>
                                    <img
                                        src={activeUser.governmentId}
                                        alt={activeUser.uid}
                                        loading="lazy"
                                        style={{maxHeight:'450px', marginTop:'40px'}}
                                    />
                                    <ImageListItemBar position="below" title={"Id"} />
                                </ImageListItem>

                            </ImageList>
                            </Container>
                                <Container>
                                    <ButtonsWrapper>
                                        <DrowpDownWrapper>
                                            <DatePicker
                                                onChange={setLicenseExpiryDate}
                                            />
                                            <Button
                                                variant="contained" color="success"
                                                onClick={() => {handleApproval('approved', activeUser.uid)}}>
                                                Approve
                                            </Button>

                                        </DrowpDownWrapper>

                                        <DropdownButton id="dropdown-item-button" title="Choose reason for rejection" variant = "success">
                                            <Dropdown.Item as="button" onClick={()=> setRejectionReason("Government Id is blurry or unreadable")}>Government Id is blurry or unreadable</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={()=>setRejectionReason("License is blurry or unreadable")}>License is blurry or unreadable</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={()=> setRejectionReason("Photo of face  is blurry")}>Photo of face  is blurry</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={()=>setRejectionReason("All photos are blurry")}>All photos are blurry</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={()=>setRejectionReason("Identity does not match")}>Identity does not match</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={()=>setRejectionReason("Practice license not provided")}>Practice license not provided</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={()=>setRejectionReason("Practice license is expired")}>Practice license is expired</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={()=>setRejectionReason("Government Id is not provided")}>Government Id not provided</Dropdown.Item>
                                        </DropdownButton>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => {handleApproval('rejected', activeUser.uid)}}>
                                            Reject
                                        </Button>

                                    </ButtonsWrapper>
                                </Container>

                            <Container>
                            <OverlayClosebtn onClick={()=>{setState({show_overlay:false})}}>x</OverlayClosebtn>
                            </Container>
                        </Container>
                        </OverlayContent>
                    </Overlay>
                </div>


            </Main>

        </div>
    )
}
