import styled from "styled-components";
import {
    Button,
    Paper,
    TableBody,
    TableContainer,
    TableCell,
    Table,
    TableHead,
    TableRow
} from '@mui/material';

export const TableWrapper = styled.div`
width: 100%;
flex-grow: 5;
`;



export const PaymentTable = (  { userData , handlePay }  ) => {
    if (userData){
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">User Recipient Code</TableCell>
                            <TableCell align="center">Balance</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userData.map((row,rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center">
                                    {row.email}
                                </TableCell>
                                <TableCell align="center">
                                    {row.recipientCode}
                                </TableCell>
                                <TableCell align="center">{row.amount/100}</TableCell>
                                <TableCell align="center">{row.status}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() => handlePay(rowIndex)}
                                        disabled={row.status === "success" || row.status === "pending"}
                                    >
                                        Pay
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }else {
        return (
            <>
                <h5> No data available</h5>
            </>
        )
    }

}
