import styled from "styled-components";
import {useEffect, useState} from "react";
import {collection, query, where, onSnapshot, updateDoc, getDocs, doc, getDoc} from "firebase/firestore";
import { db} from "../firebaseSetup";
import SideBar from "../components/Sidebar";
import {PaymentTable} from "../components/PaymentTable";
import {DropdownButton, Dropdown} from "react-bootstrap";
import {Button} from "@mui/material";

export const   Main  = styled.div`
    padding: 0px 10px;
    color: black;
    display: flex;
    flex-direction: column;
    width:100%;
`

const DataDisplayWrapper= styled.div`
    display:flex;
    flex:3;
    flex-direction: row;
    justify-content: space-evenlt;
    align-items: stretch;
`
const HeadingWrapper= styled.div`
    flex-direction: row;
    flex:1;
`
const TableWrapper= styled.div`
    flex:10;
`
const DrowpDownWrapper = styled.div`
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
`

const ButtonWrapper = styled.div`
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
`

const DataItemWrapper= styled.div`
    height: 60%;
    width:25%
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(62, 180, 137);
    border-radius: 10px;
    overflow: hidden;
    flex: 1;
    margin: 1rem;
    display:flex;
    flex-direction:column;
`
export const Payments = () => {
    const [paystackData, setPaystackData] = useState({})
    const [paymentData, setPaymentData] = useState([])
    const [filteredData, setFilteredData] = useState(paymentData)
    useEffect(()=>{
        const fetchData = async (url, options) => {
            try {
                const response = await fetch(url, options)
                if (!response.ok) {
                    throw new Error(response.statusText)
                }

                const data = await response.json()
                return data
            } catch (error) {
                return error
            }
        }

        fetchData('https://api.paystack.co/balance', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer sk_live_6b42fc122edf633f86e5336e8b0ec5c8c32c1c3d',
            }),
        }).then(result => {
            setPaystackData(result)
        })
    },[])

    useEffect(()=> {
            const fetchPaymentData = async () => {
                const payments = query(collection(db, "payments"))
                let dataList = []
                onSnapshot(payments, snapshot => {
                    snapshot.docs.map(doc => (
                         dataList.push(doc.data())
                    ))
                    setPaymentData(dataList)
                })
            }

            fetchPaymentData().catch(console.error)

        }

        ,[])

    useEffect(() => { setFilteredData(paymentData)}, [paymentData] )

    const getAmountByStatus=(status)=>{
        let dueList  = []
        paymentData.map(doc =>(doc.status === status?dueList.push(doc):()=>{}))
        let amount = 0;
        dueList.map(doc => (amount+=doc.amount))
        return amount
    }


    const  filterByStatus= (status) =>{
        let newData  = []
        paymentData.map(doc =>(newData.push(doc)))
        if (status==="all"){

            setFilteredData(newData);
        }else{

            setFilteredData(newData.filter((doc)=>{return doc.status === status}),)
        }

    }



    const handlePayment = async (rowIndex) => {
        console.log(rowIndex)
        const userCode = paymentData[rowIndex]

        const docRef = doc(db, "payments", userCode.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            initiatePaystackPayment(docSnap.data().amount, docSnap.data().recipientCode, docSnap.data().reference, docSnap.data().uid)
        }
        else {
            console.log("No such document!");
        }





    }

    const handlebulkPayment = async () => {
        const docRef = query(collection(db, "payments"), where("status", "==", "due"));
        const userBalance = await getDocs(docRef);
        userBalance.docs.forEach(doc => {
            initiatePaystackPayment(doc.data().amount, doc.data().recipientCode, doc.data().reference, doc.data().uid)
        })

    }
    const  initiatePaystackPayment =(amount, code, ref, uid)=>{
        fetch('https://api.paystack.co/transfer', {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer sk_live_6b42fc122edf633f86e5336e8b0ec5c8c32c1c3d',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                "source": "balance",
                "amount": String(amount),
                "reference": ref,
                "recipient": code,
                "reason": "Consultation Payment"
            }),
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                const docRef = doc(db, "payments", uid);
                const docSnap = await getDoc(docRef);
                await updateDoc(docSnap.ref, {
                    status: "pending",
                    transferCode: responseJson.data.transfer_code,
                    createdAt: responseJson.data.createdAt,
                })
                console.log("transfer initiated successfully")

            })
            .catch((error) => {
                console.error(error);
            });

    }

    const handleUpdateStatuses = async () => {
        const docRef = query(collection(db, "payments"), where("status", "==", "pending"));
        const userBalance = await getDocs(docRef);
        userBalance.docs.forEach(doc => {
            updatePaystackPaymentStatus(doc.data().transferCode, doc.data().uid)
        })
    }
    const  updatePaystackPaymentStatus =(transferCode, uid)=>{
        fetch('https://api.paystack.co/transfer/'+ transferCode, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer sk_live_6b42fc122edf633f86e5336e8b0ec5c8c32c1c3d'
            }),
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                const docRef = doc(db, "payments", uid);
                const docSnap = await getDoc(docRef);
                await updateDoc(docSnap.ref, {
                    status: responseJson.data.status,
                    updatedAt: responseJson.data.updatedAt,
                })
                console.log("transfer updated successfully")

            })
            .catch((error) => {
                console.error(error);
            });

    }

    return (
        <div style={{ display: "flex", height:'100%', minHeight:'850px' }}>
            <SideBar/>
            <Main>
                <HeadingWrapper>
                    <h3>Payments</h3>
                </HeadingWrapper>
                <DataDisplayWrapper>
                    <DataItemWrapper>
                        <h5>Paystack</h5>
                        {paystackData.data?<h2> {paystackData.data[0].balance/100}</h2>:<span>'loading...'</span>}
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>Due</h5>
                        <h2>{getAmountByStatus("due")/100}</h2>
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>Pending</h5>
                        <h2> {getAmountByStatus("pending")/100}</h2>
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>Paid</h5>
                        <h2>{getAmountByStatus("success")/100}</h2>
                    </DataItemWrapper>
                </DataDisplayWrapper>
                <TableWrapper>
                    <DrowpDownWrapper>
                        <ButtonWrapper>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => handlebulkPayment()}
                            >
                                Bulk Pay
                            </Button>
                        </ButtonWrapper>
                       <ButtonWrapper>
                           <Button
                               variant="contained"
                               color="success"
                               onClick={() => handleUpdateStatuses()}
                           >
                               Update status
                           </Button>
                       </ButtonWrapper>

                    </DrowpDownWrapper>
                    <DrowpDownWrapper>
                        <DropdownButton id="dropdown-item-button" title="Filter by status" variant = "success">
                            <Dropdown.Item as="button" onClick={()=>filterByStatus("all")}>All</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={()=>filterByStatus("due")}>Due</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={()=>filterByStatus("pending")}>Pending</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={()=>filterByStatus("success")}>Paid</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={()=>filterByStatus("failed")}>Failed</Dropdown.Item>
                        </DropdownButton>
                    </DrowpDownWrapper>

                    <PaymentTable userData={filteredData} handlePay={handlePayment}/>
                </TableWrapper>

            </Main>

        </div>
    )
}
