import styled from "styled-components";
import {useEffect, useState} from "react";
import {auth} from "../firebaseSetup";
import SideBar from "../components/Sidebar";
import { getPaystackBalance } from "../services/paystackApi";
import Spinner from "../components/Spinner";
import { getAllUsers, getLoggedInUserData, getPaymentDue } from "../services/databaseApi";
export const   Main  = styled.div`
    padding: 0px 10px;
    color: black;
    display: flex;
    flex-direction: column;
    width:100%;
`

const DataDisplayWrapper= styled.div`
    display:flex;
    flex:4;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
`
const HeadingWrapper= styled.div`
    flex-direction: row;
    flex:1;
`

const DataItemWrapper= styled.div`
    height: 20%;
    width:25%
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(62, 180, 137);
    border-radius: 10px;
    overflow: hidden;
    flex: 1;
    margin: 1rem;
    display:flex;
    flex-direction:column;
`
export const Home = () => {
    const [user, setUser] = useState()
    const [allUsers, setAllUsers] = useState()
    const [paymentDue, setPaymentDue] = useState()
    const [paystackBalance, setPaystackBalance] = useState()

    const getUsersCountByFilter = (filter, value)=>{
        let count = 0
        allUsers.forEach(user => {
            if(user[filter] === value){
                count = count + 1;
            }
        });
        return count;
    }
    
    useEffect(()=>{
        let isMounted  = true;
        const getBalance = async () => {
            const {data, error} = await getPaystackBalance();
            if (!isMounted){
                return;
            }
            if (data){
                setPaystackBalance(data.data[0].balance)
            }
            if (error){
                setPaystackBalance(JSON.stringify(error, null, 2))
            }
        }

        getBalance();
        return ()=>{
            isMounted = false;
        };
},[])

    useEffect(()=>{
        let isMounted  = true;
        const getUsers = async () => {
            const {data, error} = await getAllUsers();
            if (!isMounted){
                return;
            }
            if (data){
                setAllUsers(data)
            }
            if (error){
                setAllUsers([])
                console.log(JSON.stringify(error, null, 2))
            }
        }

        getUsers();
        return ()=>{
            isMounted = false;
        };
            

    },[])

   
    useEffect(()=> {
        
        let isMounted  = true;
        const getUserData = async () => {
            console.log(auth.currentUser)
            const {data, error} = await getLoggedInUserData(auth.currentUser.uid);
            if (!isMounted){
                return;
            }
            if (data){
                setUser(data)
            }
            if (error){
                console.log(JSON.stringify(error, null, 2))
            }
        }

        getUserData();
        return ()=>{
            isMounted = false;
        };

        }

    ,[])

    useEffect(()=> {
        let isMounted  = true;
        const getPaymentDueData = async () => {
            const {data, error} = await getPaymentDue();
            if (!isMounted){
                return;
            }
            if (data){
                setPaymentDue(data)
            }
            if (error){
                setPaymentDue(JSON.stringify(error, null, 2))
            }
        }

        getPaymentDueData();
        return ()=>{
            isMounted = false;
        };

    },[])
    return (
        <div style={{ display: "flex", height:'100%', minHeight:'850px' }}>
            <SideBar/>
            <Main>
                <HeadingWrapper>
                    <h4>Welcome, {user?.firstname}</h4>
                </HeadingWrapper>
                <DataDisplayWrapper>
                <DataItemWrapper>
                        <h5>Total Users</h5>
                        {allUsers?<h2> {allUsers.length}</h2>:<Spinner/>}
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>Unapproved HPs</h5>
                        {allUsers?<h2> {getUsersCountByFilter('certified','pending')}</h2>:<Spinner/>}
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>Approved HPs</h5>
                        {allUsers?<h2> {getUsersCountByFilter('certified','approved')}</h2>:<Spinner/>}
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>General Users</h5>
                        {allUsers?<h2> {getUsersCountByFilter('userType','patient')}</h2>:<Spinner/>}
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>Paystack Balance</h5>
                        {paystackBalance?<h2> {paystackBalance/100}</h2>:<Spinner/>}
                    </DataItemWrapper>
                    <DataItemWrapper>
                        <h5>Payments Due</h5>
                        {paymentDue?<h2> {paymentDue/100}</h2>:<Spinner/>}
                    </DataItemWrapper>
                    {/*{approvedEmails? approvedEmails.map((acc)=><h5>{acc.email} - {acc.firstname} - workplace - {acc.workplace}</h5>):<h5>nothing here ...</h5>}*/}
                </DataDisplayWrapper>

            </Main>

        </div>
    )
}
