import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {SignIn} from "./pages/SignIn";
import {Home} from "./pages/Home";
import {ErrorLogIn} from "./pages/ErrorLogIn";
import {Approvals} from "./pages/Approvals";
import {Public} from "./pages/Public";
import {Payments} from "./pages/Payments";
import ProtectedRoute from './components/ProtectedRoute';


function App() {
  return (
      <BrowserRouter>
        <main>
          <Routes>
            <Route path="/" element={<Public/>} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/home" element={<ProtectedRoute Component={Home}/>} />
              <Route path="/verify" element={<Approvals />} />
              <Route path="/payment" element={<Payments />} />
              <Route path="/error" element={<ErrorLogIn/>}/>
          </Routes>
        </main>
      </BrowserRouter>
  );
}

export default App;
