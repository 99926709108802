
import styled from "styled-components";
export const   Main  = styled.div`
    margin-left: 250px; /* Same as the width of the sidebar */
    padding: 0px 10px;
`
export const ErrorLogIn = () => {
    return (
        <>

            <Main>
                <h1>403:  Forbidden</h1>
            </Main>

        </>
    )
}